import Sortable from 'sortablejs';
import * as bootstrap from 'bootstrap'

import DefaultController from "../../../defaultController";
import Utils from "../../../../utils";

export default class TeamsTimetrackList extends DefaultController {
    private offcanvas: any;
    private editModal: any;
    private currentCorrectId: any;
    private currentCorrectDate: any;

    async init() {
        this.offcanvas = bootstrap.Offcanvas.getOrCreateInstance((document.querySelector("#offcanvasCalendar") as HTMLElement));
        this.editModal = new bootstrap.Modal((document.querySelector("#timetrackCreateRequest") as HTMLElement));
        super.init();
    }

    translateStamp(name: string) {
        if (name === "IN") {
            return Utils.translate('timetrack.states.start');
        } else if (name === "OUT") {
            return Utils.translate('timetrack.states.end');
        } else if (name === "P_IN") {
            return Utils.translate('timetrack.states.pause');
        } else if (name === "P_OUT") {
            return Utils.translate('timetrack.states.pause_end');
        }
    }

    translateAllStamps(str: string) {
        let s = str
        const modes = ["P_IN", "P_OUT", "IN", "OUT"]
        modes.forEach((t: string) => {
            // @ts-ignore
            s = s.replaceAll(t, this.translateStamp(t));
        })
        return s;
    }

    async validateStamps(e: any) {
        const elems = e.querySelectorAll(".list-group-item")
        const config: any[] = []
        elems.forEach((p: any, i: number) => {
            const time = p.querySelector('.stamp_time').value || p.querySelector('.stamp_time').innerHTML
            const type = p.querySelector('.stamp_type').getAttribute("data-type")
            config.push({mode: type, at: time})
        })
        const calculated = await Utils.entity.request('/api/v1/time_entries/calculate', 'POST', {
            date: this.currentCorrectDate,
            config: config,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        const errors: any[] = calculated.messages
        const modalBody = e.closest(".modal-dialog");
        modalBody.querySelector(".time_entires_msg").classList.remove("d-none")
        if (errors.length > 0) {
            modalBody.querySelector(".time_entries_save").disabled = true
            modalBody.querySelector(".time_entires_msg").classList.remove("alert-success")
            modalBody.querySelector(".time_entires_msg").classList.add("alert-danger")
            modalBody.querySelector(".time_entires_msg").innerHTML = this.translateAllStamps(errors.join("<br />"))
        } else {
            modalBody.querySelector(".time_entries_save").disabled = false
            modalBody.querySelector(".time_entires_msg").classList.add("alert-success")
            modalBody.querySelector(".time_entires_msg").classList.remove("alert-danger")
            modalBody.querySelector(".time_entires_msg").innerHTML = "Validierung erfolgreich!"

        }
        let diffPlanWorking = parseInt(calculated.working) - parseInt(calculated.planned)
        let overviewHtml = `<li class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-success"><i class="ti ti-player-play-filled ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${Utils.translate("timetrack.work_time")}</h6>
                    <small class="text-muted">${Utils.secondsToHhMm(parseInt(calculated.working))} / ${Utils.secondsToHhMm(parseInt(calculated.planned))}</small>
                  </div>
                  <div class="user-progress d-flex align-items-center gap-3">
                    <div class="d-flex align-items-center gap-1">
                      <i class="ti ${diffPlanWorking > 0 ? 'ti-chevron-up text-success' : 'ti-chevron-down text-danger'}"></i>
                      <small class="text-muted">${Utils.secondsToHhMm(diffPlanWorking)}</small>
                    </div>
                  </div>
                </div>
              </li>
              <li class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-warning"><i class="ti ti-player-pause-filled ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${Utils.translate("timetrack.pause_time")}</h6>
                    <small class="text-muted">${Utils.secondsToHhMm(parseInt(calculated.pause))}</small>
                  </div>
                </div>
              </li>`;
        (modalBody.querySelector('.timeline-overview') as HTMLElement).innerHTML = overviewHtml;
    }

    protected setupModalSort() {
        const parent = document.querySelector(".time_entries_main") as HTMLElement;
        const l = parent.querySelector(".time_entries_left") as HTMLElement
        const r = parent.querySelector(".time_entries_right") as HTMLElement
        const list = new Sortable(l, {
            group: {
                name: 'timeCorrect',
                pull: 'clone' // To clone: set pull to 'clone'
            },
            animation: 150,
            onAdd: (e: any) => {
                const oldItemType = e.item.querySelector(".stamp_type").getAttribute("data-type")
                e.item.innerHTML =
                    '<div class="row justify-content-between">' +
                    '<div class="col-5"> ' +
                    '<input type="time" class="form-control stamp_time" step="1" /> ' +
                    '</div> ' +
                    '<div class="col-4"> ' +
                    '<span class="stamp_type" data-type="' + oldItemType + '">' + this.translateStamp(oldItemType) + '</span>' +
                    '</div> ' +
                    '<div class="col-3"> ' +
                    '<button class="btn btn-sm btn-danger time_entries_delete">X</button>' +
                    '</div> ' +
                    '</div> '
                this.validateStamps(l);
            },
            onUpdate: (e: any) => {
                this.validateStamps(e.from);
            }
        });

        new Sortable(r, {
            group: {
                name: 'timeCorrect',
                pull: 'clone',
                put: false
            },
            animation: 150
        });

        l.addEventListener("click", (e) => {
            const target = e.target
            if ((e.target as HTMLElement).classList.contains("time_entries_delete")) {
                e.preventDefault();
                // @ts-ignore
                l.removeChild((target as HTMLElement).closest(".list-group-item"));
                this.validateStamps(l);
            }
        })

        l.addEventListener("change", (e) => {
            if ((e.target as HTMLElement).classList.contains("stamp_time")) {
                this.validateStamps(l);
            }
        })
        this.validateStamps(l);
    }

    protected bindListeners() {
        (document.querySelector(".time_entries_save") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const elems = document.querySelectorAll(".time_entries_left .list-group-item")
            const config: any[] = []
            elems.forEach((p: any, i: number) => {
                const time = p.querySelector('.stamp_time').value || p.querySelector('.stamp_time').innerHTML
                const type = p.querySelector('.stamp_type').getAttribute("data-type")
                config.push({mode: type, at: time})
            })
            console.log(config, this.currentCorrectDate)
            if (this.currentCorrectId === "TODAY") {
                await Utils.entity.upsert({uuid: "", config_new: config}, 'time_entries/today')
            } else {
                await Utils.entity.upsert({uuid: this.currentCorrectId, config_new: config}, 'time_entry_committeds')
            }
            this.editModal.hide()
            this.offcanvas.hide()
            this.toastr.success(`${Utils.translate('time_entry_committed.name')} ${Utils.translate('generic.messages.created')}`, `${Utils.translate('generic.success')}`)

        });

        (document.querySelector("#timetrack_change_request") as HTMLElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const target = e.currentTarget as HTMLElement
            if (target) {
                const id = target.getAttribute("data-id")
                let requestData: any = {}
                if (id === "TODAY") {
                    const data = await Utils.entity.get("", 'time_entries/today')
                    requestData = {
                        status: data.status,
                        data: [
                            {
                                date: new Date().toISOString().split('T')[0],
                                config: data.config
                            }
                        ]
                    };
                    (document.querySelector(".time_entries_save") as HTMLButtonElement).innerHTML = 'Korrigieren';
                } else {
                    requestData = await Utils.entity.get(id, "time_entry_committeds");
                    (document.querySelector(".time_entries_save") as HTMLButtonElement).innerHTML = 'Korrektur anfragen';
                }
                const form = (document.querySelector("#timetrackCreateRequestForm") as HTMLFormElement);
                form.reset();
                if (requestData.status === 200) {
                    const data = requestData.data[0]
                    this.currentCorrectDate = data.date
                    this.currentCorrectId = id;
                    let html = ''
                    for (let stamp of data.config) {
                        html += `<div class="list-group-item">
                        <div class="row justify-content-between">
                          <div class="col-4">
                            <span class="stamp_time">${new Date(Date.parse(stamp.at)).toLocaleTimeString()}</span>
                          </div>
                          <div class="col-2">
                            <span class="stamp_type" data-type="${stamp.mode}">${this.translateStamp(stamp.mode)}</span>
                          </div>
                          <div class="col-2">
                            <button class="btn btn-sm btn-danger time_entries_delete">X</button>
                          </div>
                        </div>
                      </div>`
                    }
                    if (data.config.length === 0) {
                        let autoFrom = target.getAttribute("data-auto-from")
                        let autoTo = target.getAttribute("data-auto-to")
                        if (autoFrom && autoTo) {
                            html += `<div class="list-group-item">
                                <div class="row justify-content-between">
                                  <div class="col-4">
                                    <span class="stamp_time">${new Date(Date.parse(`${this.currentCorrectDate} ${autoFrom}`)).toLocaleTimeString()}</span>
                                  </div>
                                  <div class="col-2">
                                    <span class="stamp_type" data-type="${"IN"}">${this.translateStamp("IN")}</span>
                                  </div>
                                  <div class="col-2">
                                    <button class="btn btn-sm btn-danger time_entries_delete">X</button>
                                  </div>
                                </div>
                              </div>`
                            html += `<div class="list-group-item">
                                <div class="row justify-content-between">
                                  <div class="col-4">
                                    <span class="stamp_time">${new Date(Date.parse(`${this.currentCorrectDate} ${autoTo}`)).toLocaleTimeString()}</span>
                                  </div>
                                  <div class="col-2">
                                    <span class="stamp_type" data-type="${"OUT"}">${this.translateStamp("OUT")}</span>
                                  </div>
                                  <div class="col-2">
                                    <button class="btn btn-sm btn-danger time_entries_delete">X</button>
                                  </div>
                                </div>
                              </div>`
                        }
                    }
                    (form.querySelector(".time_entries_left") as HTMLElement).innerHTML = html;
                    this.setupModalSort();
                    await Utils.updateElements(data, '', form)
                    this.editModal.show();
                }
            }
        });

        (document.querySelectorAll(".timetrack_open_offcanvas") as NodeListOf<HTMLElement>).forEach((elem) => {
            elem.addEventListener("click", async (e) => {
                const target = e.currentTarget as HTMLElement;
                const id = target.getAttribute("data-id");
                const data = JSON.parse(atob((target.getAttribute("data-config") as string)));
                const date = target.getAttribute("data-date") as string;
                const html = await this.generateHtml(data,
                    (target.getAttribute("data-working") as string),
                    (target.getAttribute("data-planned") as string),
                    (target.getAttribute("data-pause") as string),
                    (target.getAttribute("data-status") as string),
                    (target.getAttribute("data-reasoning") as string),
                    JSON.parse(atob(target.getAttribute("data-absences") as string)),
                    JSON.parse(atob(target.getAttribute("data-trips") as string)));

                let autobookFrom = null;
                let autobookTo = null;
                if (target.getAttribute("data-time-from")) {
                    autobookFrom = target.getAttribute("data-time-from");
                }
                if (target.getAttribute("data-time-to")) {
                    autobookTo = target.getAttribute("data-time-to");
                }
                if (html.showChangeButton) {
                    this.offcanvas._element.querySelector("#timetrack_change_request").innerHTML = Utils.translate("timetrack.create_change_request");
                    this.offcanvas._element.querySelector("#timetrack_change_request").setAttribute("data-id", `${id}`);
                } else {
                    this.offcanvas._element.querySelector("#timetrack_change_request").innerHTML = Utils.translate("timetrack.modify_times");
                    this.offcanvas._element.querySelector("#timetrack_change_request").setAttribute("data-id", "TODAY")
                }
                if (autobookFrom) {
                    this.offcanvas._element.querySelector("#timetrack_change_request").setAttribute("data-auto-from", autobookFrom)
                } else {
                    this.offcanvas._element.querySelector("#timetrack_change_request").removeAttribute("data-auto-from")
                }
                if (autobookTo) {
                    this.offcanvas._element.querySelector("#timetrack_change_request").setAttribute("data-auto-to", autobookTo)
                } else {
                    this.offcanvas._element.querySelector("#timetrack_change_request").removeAttribute("data-auto-to")
                }
                this.offcanvas._element.querySelector(".offcanvas-title").innerHTML = date
                this.offcanvas._element.querySelector("ul.timeline-advance").innerHTML = html.ul
                this.offcanvas._element.querySelector("ul.timeline-overview").innerHTML = html.overview
                this.offcanvas._element.querySelector("#timetrack_correction").innerHTML = html.correction

                this.offcanvas.show();
            })
        })
    }

    async generateHtml(data: any, full_working: string, full_planned: string, full_pause: string, status: string, reasoning: any, absences: any, trips: any) {
        let correctionStatusBsColor = 'warning';
        let correctionStatusIcon = 'reload';
        let correctionStatusText = Utils.translate("timetrack.messages.not_booked")
        if (status && status === 'PENDING') {
            correctionStatusBsColor = 'danger';
            correctionStatusIcon = 'x';
            correctionStatusText = Utils.translate("timetrack.messages.pending")
        } else if (status && status === 'OK') {
            correctionStatusBsColor = 'success';
            correctionStatusIcon = 'check';
            correctionStatusText = Utils.translate("timetrack.messages.times_ok")
        } else if (status && status === 'CORRECT_REQUESTED') {
            correctionStatusBsColor = 'danger';
            correctionStatusIcon = 'reload';
            correctionStatusText = Utils.translate("timetrack.messages.correct_requested")
        }

        let correctionHtml = `<div class="d-flex mb-3">
                <div class="avatar flex-shrink-0 me-3">
                  <span class="avatar-initial rounded bg-label-${correctionStatusBsColor}"><i class="ti ti-${correctionStatusIcon} ti-sm"></i></span>
                </div>
                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                  <div class="me-2">
                    <h6 class="mb-0">${correctionStatusText}</h6>
                  </div>
                </div>
              </div>`;
        return {
            ul: `${Utils.timeTrack.generateList(data)}`,
            overview: Utils.timeTrack.generateOverview(full_working, full_planned, full_pause, absences, trips),
            correction: correctionHtml,
            showChangeButton: status.length > 0
        }
    }

}